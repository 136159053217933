import axios from 'axios'
import bus from '@/bus'
import getEnv from '@/utils/env'

export const WS_EVENTS = {
  ERROR: 'wsError',
  DELIVERY_BILL_CREATED: 'wsData/delivery_bill_created',
  DELIVERY_BILL_UPDATED: 'wsData/delivery_bill_updated',
  RG_REPORT_CREATED: 'wsData/rg_report_created',
  RG_REPORT_UPDATED: 'wsData/rg_report_updated',
}

const SERVER_EVENTS = {
  DELIVERY_BILL_CREATED: 'delivery_bill_created',
  DELIVERY_BILL_UPDATED: 'delivery_bill_updated',
  RG_REPORT_CREATED: 'rg_report_created',
  RG_REPORT_UPDATED: 'rg_report_updated',
}

export const WS_ACTIONS = {
  JOIN_ROOM: 'join_room',
  LEAVE_ROOM: 'leave_room',
}

export const WS_ROOMS = {
  DELIVERY_STATUS: 'delivery_status_room',
  RG_REPORT_STATUS: 'rg_report_status_room',
}

const joinedRooms = {}
let connection = null
const pendingOperations = []

const isConnectionHealthy = () => {
  if (connection && connection.readyState === 1) {
    return true
  }
  return false
}

const joinRoom = roomName => {
  if (!isConnectionHealthy()) {
    pendingOperations.push({
      action: WS_ACTIONS.JOIN_ROOM,
      roomName,
    })
    return
  }

  if (!joinedRooms[roomName]) {
    joinedRooms[roomName] = 0
    connection.send(JSON.stringify({
      type: WS_ACTIONS.JOIN_ROOM,
      room_name: roomName,
    }))
  }
  joinedRooms[roomName] += 1
}

const leaveRoom = roomName => {
  if (!isConnectionHealthy()) {
    pendingOperations.push({
      action: WS_ACTIONS.LEAVE_ROOM,
      roomName,
    })
    return
  }

  joinedRooms[roomName] -= 1
  if (joinedRooms[roomName] === 0) {
    connection.send(JSON.stringify({
      type: WS_ACTIONS.LEAVE_ROOM,
      room_name: roomName,
    }))
    delete joinedRooms[roomName]
  }
}

const resolvePendingOperations = () => {
  pendingOperations.forEach(operation => {
    const { action, roomName } = operation

    if (action === WS_ACTIONS.JOIN_ROOM) {
      joinRoom(roomName)
    } else if (action === WS_ACTIONS.LEAVE_ROOM) {
      leaveRoom(roomName)
    }
  })
}
const messageHandler = event => {
  const eventData = JSON.parse(event.data)
  const { type, data } = eventData

  if (type === SERVER_EVENTS.DELIVERY_BILL_CREATED) {
    bus.$emit(WS_EVENTS.DELIVERY_BILL_CREATED, data)
  } else if (type === SERVER_EVENTS.DELIVERY_BILL_UPDATED) {
    bus.$emit(WS_EVENTS.DELIVERY_BILL_UPDATED, data)
  } else if (type === SERVER_EVENTS.RG_REPORT_CREATED) {
    bus.$emit(WS_EVENTS.RG_REPORT_CREATED, data)
  } else if (type === SERVER_EVENTS.RG_REPORT_UPDATED) {
    bus.$emit(WS_EVENTS.RG_REPORT_UPDATED, data)
  }
}

const createConnection = async () => {
  axios.post('/ws/websocket_ticket/').then(response => {
    const wsTicket = response.data.ws_ticket

    connection = new WebSocket(`${getEnv('VUE_APP_WEBSOCKET_URL')}?ws_ticket=${wsTicket}`)

    connection.onopen = () => {
      resolvePendingOperations()
    }

    connection.onmessage = event => {
      messageHandler(event)
    }

    connection.onclose = event => {
      if (!event.wasClean) {
        bus.$emit(WS_EVENTS.ERROR, 'Websocket connection failed')
      }
    }
  }).catch(() => {
    bus.$emit(WS_EVENTS.ERROR, 'Error fetching websocket ticket')
  })
}

const closeConnection = () => {
  if (connection) {
    connection.close()
  }
}

const WS = {
  createConnection,
  closeConnection,
  joinRoom,
  leaveRoom,
}

export default WS
